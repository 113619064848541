// const url = "https://afphabitat.hmycia.com/menu"
// const url = "http://localhost:3000/menu"
const url = "https://afpmodelo.hmycia.com/menu"
// const url = "https://d3cpoa64ld748k.cloudfront.net/menu"

const objetoReturn = {
    url: url,
    // urlApi: "https://d1tl5p9wrfnj29.cloudfront.net/",
    // urlApi: "https://0t0ava1c7h.execute-api.us-east-2.amazonaws.com/prd/",
    post: "https://0t0ava1c7h.execute-api.us-east-2.amazonaws.com/prd/",
    urlApi: "https://0t0ava1c7h.execute-api.us-east-2.amazonaws.com/prd/",
    msalConfig: {
        auth: {
            clientId: '27fcc8a1-ccf5-461c-b9ef-0ab5ca0bd016',
            authority: "https://login.microsoftonline.com/ec596c3e-8fa2-40c7-a89a-ec487763d282",
            redirectUri: url,
            postLogoutRedirectUri: url,
        }
    },
    msalConfigClientSilentToken: {
        auth: {
            clientId: '4fd084f4-853a-4a2e-bb12-d9cc7a4e9d51',
            authority: "https://login.microsoftonline.com/ec596c3e-8fa2-40c7-a89a-ec487763d282",
            redirectUri: url,
            postLogoutRedirectUri: url,
        }
    }
}



export default objetoReturn