/* eslint-disable default-case */
import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { AccountCircle, LockRounded } from "@material-ui/icons";
import logo from "./logohmycia.jpg";
import "./signin.css";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import { Context } from '../AppContext'
import Parametros from '../Parametros'
import * as msal from '@azure/msal-browser'

class SignIn extends React.Component {




  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidUpdate() {
    const { store } = this.context

    if (store.currentUser) {
      this.props.history.push('/')
    }
  }

  async componentDidMount() {
    const { actions } = this.context
    await actions.authMe()
  }

  login = async () => {

    try {
      const msalInstance = new msal.PublicClientApplication(Parametros.msalConfigClientSilentToken);
      let loginResponse = await msalInstance.loginPopup({});

      if (loginResponse.accessToken) {

        const accessTokenRequest = {
          scopes: ["api://b7c28916-29d9-4ee9-9c3e-49e5b72e5dc3/access_as_user"],
          account: loginResponse.account,
        };

        const { actions } = this.context

        let responseSilent = await msalInstance.acquireTokenSilent(
          accessTokenRequest
        );

        await actions.login(this.state.email, responseSilent.accessToken, responseSilent.accessToken)
        this.props.history.push('/')
      } else {
        alert("Error al iniciar")
      }
    } catch {
      
    }

  }

  changeEmail = async (e) => {
    const email = e.target.value
    await this.setState({
      email,
    })
  }

  changePassword = async (e) => {
    const password = e.target.value
    await this.setState({
      password,
    })
  }

  render() {
    return (
      <div className="main">
        <Grid
          container
          direction='column'
          alignItems="center"
          spacing={3}
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            style={{
              maxWidth: 440,
              minWidth: 300,
              padding: '50px 0',
              backgroundColor: '#fff',
              borderRadius: '.5rem',
            }}
          >
            <Grid item xs={12}>
              <img
                src={logo}
                alt=""
                className="logo-principal"
                width="350px"
                height="140px"
              />
            </Grid>
            {/* <Grid item xs={12} style={{ marginTop: '1rem' }}>
              <TextField
                label="Email"
                margin="normal"
                value={this.state.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ paddingRight: '8px' }}>
                      <AccountCircle />
                    </InputAdornment>
                  )
                }}
                onChange={this.changeEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                type="password"
                value={this.state.password}
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ paddingRight: '8px' }}>
                      <LockRounded />
                    </InputAdornment>
                  )
                }}
                onChange={this.changePassword}
              />
            </Grid> */}

            <Grid item xs={12} style={{ marginTop: '2rem' }}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={this.login}
              >Sign In</Button>
            </Grid>

            {/* <Grid item xs={12}>
              {this.state.user ? (
                <StyledButton primary>
                  Loading
                </StyledButton>
              ) : (
                <Link to="/">
                  <StyledButton
                    primary
                    onClick={() => {
                      this.setUser();
                    }}
                  >
                    Inicia sesión con Google
                  </StyledButton>
                </Link>
              )}
            </Grid> */}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(SignIn);
