import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../AppContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import StyledInput from "../styles/StyledInput";
import SearchIcon from "../styles/SearchIcon";
import StyledTitle from "../styles/StyledTitle";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import MainNavbar from "../components/MainNavbar";
import Container from "@material-ui/core/Container";
import Calendar from "../components/Calendar";
import StyledButton from "../styles/StyledButton";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    MuiTableCell: {
      fontSize: "26px !important",
    },
    color: "#fafafa !important",
  },
  table: {
    marginTop: "60px",
  },
});

function Row(props) {
  const { item, total } = props;
  const [open, setOpen] = React.useState("");
  const classes = useRowStyles();
  const { store, actions } = useContext(Context);

 console.log(item)
  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <input
            onChange={(e) => actions.handleCheckbox(e)}
            type="checkbox"
            checked={item.selected}
            id={item.key}
          />
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(item.key)}
          >
            {open === item.key ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {item.id}
        </TableCell>

        <TableCell
          align="center"
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          Total: {Math.round(total)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Rut Afiliado</TableCell>
                    <TableCell>Nombre completo</TableCell>
                    {/* <TableCell>Apellido</TableCell> */}
                    <TableCell align="right">Monto Nominal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.deudas.map((afiliado, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {afiliado.idPersona}
                      </TableCell>
                      <TableCell>{afiliado.nombreAfiliado}</TableCell>
                      {/* <TableCell>{afiliado.ap1Afiliado}</TableCell> */}
                      <TableCell align="right">
                        ${Math.round(afiliado.montoNominal)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function CollapsibleTable() {
  const classes = useRowStyles();
  const { store, actions } = useContext(Context);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    actions.resetAll();
  }, []);

  return (
    <>
      <MainNavbar />
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
      >
        <Grid item xs={6}>
          <StyledTitle>Ingresa el número de Rut de la empresa</StyledTitle>
        </Grid>
        <Grid item xs={6}>
          <StyledTitle>Selecciona la fecha de pago</StyledTitle>
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            rutEmpresa
            name="rut"
            maxLength="9"
            placeholder="Rut empresa"
            onChange={(e) => actions.handleChange(e)}
          />
          <SearchIcon
            size="25"
            onClick={(e) => {
              // 768326304
              console.log(store)
               actions.getAfiliados(store.rut);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Calendar />
        </Grid>
      </Grid>
      <Container maxWidth="md">
        <TableContainer component={Paper} className={classes.table}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
            style={{
              fontSize: "22px",
              fontWeight: "800",
              textAlign: "center",
              backgroundColor: "#25AAE2",
            }}
          >
            JUDICIAL
          </Typography>
          <Table aria-label="collapsible table">
            <TableBody>
              {store.rut &&
                  store.afiliadosJudicial.map((item, i) => {
                    let total = item.deudas.reduce(
                      (total, obj) => obj.montoNominal + total,
                      0
                    );
                    return (
                      <>
                        <TableRow key={item.id} className={classes.root}>
                          <TableCell>
                            <input
                              onChange={(e) => actions.handleCheckbox(e)}
                              type="checkbox"
                              checked={item.selected}
                              id={item.id}
                            />
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpen(!open)}
                            >
                              {open ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ fontSize: "20px", fontWeight: "bold" }}
                          >
                            {item.id}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: "20px", fontWeight: "bold" }}
                          >
                            Total: {total}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={6}
                          >
                            <Collapse in={open} timeout="auto" unmountOnExit>
                              <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Rut Afiliado</TableCell>
                                      <TableCell>Nombre completo</TableCell>
                                      <TableCell align="right">
                                        Monto Nominal
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {item.deudas.map((afiliado, i) => (
                                      <TableRow key={i}>
                                        <TableCell component="th" scope="row">
                                          {afiliado.idPersona}
                                        </TableCell>
                                        <TableCell>
                                          {afiliado.nombreAfiliado}
                                        </TableCell>
                                        {/* <TableCell>
                                          {afiliado.ap1Afiliado}
                                        </TableCell> */}
                                        <TableCell align="right">
                                          ${Math.round(afiliado.montoNominal)}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              {/* {store.rut &&
                store.afiliadosJudicial.map((item, i) => {
                  console.log(item)
                  let total = item.deudas.reduce(
                    (total, obj) => obj.montoNominal + total,
                    0
                  );
                  return (
                    <Row
                      key={i}
                      item={item}
                      total={total}
                      style={{ fontSize: "22px" }}
                    />
                  );
                })} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer
          component={Paper}
          className={classes.table}
          style={{ marginBottom: "60px" }}
        >
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
            style={{
              fontSize: "22px",
              fontWeight: "800",
              textAlign: "center",
              backgroundColor: "#25AAE2",
            }}
          >
            PREJUDICIAL
          </Typography>
          <Table aria-label="collapsible table">
            <TableBody>
              {store.rut &&
                store.afiliadosPre.map((item, i) => {
                  let total = item.deudas.reduce(
                    (total, obj) => obj.montoNominal + total,
                    0
                  );
                  return (
                    <>
                      <TableRow key={item.id} className={classes.root}>
                        <TableCell>
                          <input
                            onChange={(e) => actions.handleCheckboxPre(e)}
                            type="checkbox"
                            checked={item.selected}
                            id={item.id}
                          />
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                          >
                            {open ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          {item.id}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          Total: {total}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                              <Table size="small" aria-label="purchases">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Rut Afiliado</TableCell>
                                    <TableCell>Nombre completo</TableCell>
                                    {/* <TableCell>Apellido</TableCell> */}
                                    <TableCell align="right">
                                      Monto Nominal
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {item.deudas.map((afiliado, i) => (
                                    <TableRow key={i}>
                                      <TableCell component="th" scope="row">
                                        {afiliado.idPersona}
                                      </TableCell>
                                      <TableCell>
                                        {afiliado.nombreAfiliado}
                                      </TableCell>
                                      {/* <TableCell>
                                        {afiliado.ap1Afiliado}
                                      </TableCell> */}
                                      <TableCell align="right">
                                        ${Math.round(afiliado.montoNominal)}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        {(store.afiliadosCalculo.length > 1 ||
          store.afiliadosCalculoPre.length > 1) && (
            <Link to="/calculator">
              <StyledButton
                primary
                rut={store.rut}
                date={store.date}
                judicial={store.afiliadosCalculo}
                prejudicial={store.afiliadosCalculoPre}
                onClick={(e) => actions.getCalculo(e)}
                style={{ marginRight: "60px", marginBottom: "50px" }}
              >
                Calcular{" "}
              </StyledButton>
            </Link>
          )}
      </Grid>
    </>
  );
}
