/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
import React, { useContext } from "react";
import StyledButton from "../styles/StyledButton";
import jsPDF from "jspdf";
import { Context } from "../AppContext";

export default function PdfSec({ data }) {
  const { store, actions } = useContext(Context);

  // JSpdf Generator For generating the PDF
  const jsPdfGenerator = () => {
    // Example From https://parall.ax/products/jspdf
    var doc = new jsPDF("l", "pt");
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    var imgData = "";
    doc.addImage(imgData, "JPEG", 0, 0, width, height);
    let sis = 0;
    let totalSis = 0;
    let totalCot = 0;
    let totalCotHoja = [];
    let totalRemHoja = [];
    let totalSisHoja = [];
    let totalReajusteHoja = [];
    let totalInteresHoja = [];
    let totalReajuste = 0;
    let totalInteres = 0;
    let totalRem = 0;
    let paginacion = 0
    let subTotalCot = 0;
    let subTotalSis = 0;
    let subTotalRem = 0;
    let admin = 0;
    let deposit = 0;
    let remun = 0;
    let cotizacion = 0;
    let totalRecargo = 0;
    let totalRecargoHoja = [];
    let pagina = 0;
    let day = store.date.slice(6, 8);
    let month = store.date.slice(4, 6);
    let year = store.date.slice(0, 4);
    let countFolio = 0;
    let name = ''
    let ap1 = ''
    let ap2 = ''
    doc.deletePage(1);

    store.afiliadosCalculo.map((a) => {
      countFolio++;
      let periodoAño = a.slice(0, 4);
      let periodoMes = a.slice(4, 6);
      console.log(store.afiliadosPre2);
      store.afiliadosJudicial2.map((result) => {
        let cantidad = Math.ceil(result.deudas.length / 10);
        if (result.id === a) {
          for (let i = 1; i <= cantidad; i++) {
            doc.addPage(width, height);

            doc.addImage(imgData, "JPEG", 0, 0, width, height);

            //Sección 1
            console.log(store.folio)
            doc.setFontSize(8);
            doc.text(735, 102, store.folio[countFolio - 1].formNumber);

            doc.setFontSize(8);
            doc.text(55, 129, data.razonSocial);

            doc.setFontSize(8);
            doc.text(635, 129, data.idEmpleador);

            doc.setFontSize(8);
            doc.text(55, 146, data.domicilio);

            doc.setFontSize(8);
            doc.text(410, 146, data.comuna);

            doc.setFontSize(8);
            doc.text(506, 146, data.ciudad);

            doc.setFontSize(8);
            doc.text(582, 146, data.region);

            doc.setFontSize(8);
            doc.text(604, 146, data.email);

            doc.setFontSize(8);
            doc.text(752, 146, data.celNumber);

            doc.setFontSize(8);
            doc.text(55, 167, data.nombreRepLegal);

            doc.setFontSize(8);
            doc.text(630, 167, data.rutRepLegal);


            //Sección 2

            //Seccion 3
            

            //Seccion 4

            doc.setFontSize(7);
            doc.text(105, 438, "X");

            doc.setFontSize(7);
            doc.text(100, 456, periodoMes);

            doc.setFontSize(7);
            doc.text(120, 456, periodoAño);

            doc.setFontSize(7);
            doc.text(97, 477, "X");

            doc.setFontSize(7);
            doc.text(218, 482, day);

            doc.setFontSize(7);
            doc.text(232, 482, month);

            doc.setFontSize(7);
            doc.text(246, 482, year);

            doc.setFontSize(7);
            doc.text(336, 482, '');

            //Sección 5

            doc.setFontSize(7);
            doc.text(513, 438, "X");

            /* doc.setFontSize(7);
          doc.text(523, 438, "X");*/

            doc.setFontSize(7);
            doc.text(685, 437, "X");
          }

          const findItemById = (id) =>
            result.deudas.filter((item) => item.idPersona === id);
          const entityArray = result.deudas.reduce((acc, cur) => {
            let val = acc.find(({ idPersona }) => idPersona === cur.idPersona);
            if (!val) {
              val = {};
              acc.push(val);
            }
            val.idPersona = cur.idPersona;
            val.glosas = findItemById(cur.idPersona);
            return acc;
          }, []);
          let counter = 0;
          let subcounter = 0;
          let page = 0;
          var pageTotal = doc.internal.getNumberOfPages();
          console.log(entityArray);
          entityArray.map((item) => {
            counter++;
            subcounter++;
            item.glosas.map((glosa) => {
              remun = glosa.remuneracionImponible;
              totalReajuste = glosa.reajuste + totalReajuste
              totalInteres = glosa.interes + totalInteres
              totalRecargo = glosa.recargoAfiliado + totalRecargo
              name = glosa.nombreAfiliado
              ap1 = glosa.ap1Afiliado
              ap2 = glosa.ap2Afiliado
              if (glosa.tipoProducto === 60) {
                sis = glosa.montoNominal;
                totalSis = totalSis + sis;

                return sis;
              } else if (glosa.tipoProducto === 10) {
                admin = glosa.montoNominal;
                cotizacion = admin + deposit;
                totalCot = totalCot + cotizacion;
                return admin;
              } else if (glosa.tipoProducto === 61) {
                deposit = glosa.montoNominal;
                cotizacion = admin + deposit;
                //totalCot = totalCot + cotizacion;
                return deposit;
              }
            });
            console.log('REMUNERACION', remun)
              totalRem = totalRem + remun
            doc.setFontSize(7);
            doc.text(632, 302, "0");
            console.log(
              "subcounter",
              subcounter,
              "Array",
              entityArray.length - 1
            );
            if (counter > 9) {
              page = page + 1;
              doc.setPage(page);
              counter = 0;
              totalCotHoja.push(totalCot);
              subTotalCot = totalCot + subTotalCot;
              subTotalSis = totalSis + subTotalSis;
              subTotalRem = remun + subTotalRem;
              totalSisHoja.push(totalSis);
              totalRemHoja.push(totalRem);
              totalReajusteHoja.push(totalReajuste);
              totalInteresHoja.push(totalInteres);
              totalRecargoHoja.push(totalRecargo);
              totalCot = 0;
              totalSis = 0;
              totalRem = 0;
              totalReajuste = 0;
              totalInteres = 0;
              console.log(pageTotal, page);
            } else if (counter < 9 && subcounter === entityArray.length) {
              totalCotHoja.push(totalCot);
              totalSisHoja.push(totalSis);
              totalRemHoja.push(totalRem);
              totalReajusteHoja.push(totalReajuste);
              totalInteresHoja.push(totalInteres);
              totalRecargoHoja.push(totalRecargo);
              subTotalCot = totalCot + subTotalCot;
              subTotalSis = totalSis + subTotalSis;
              subTotalRem = remun + subTotalRem;
              totalCot = 0;
              totalSis = 0;
              totalRem = 0;
              totalReajuste = 0;
              totalInteres = 0;
            }
            if (entityArray.indexOf(item) === 0) {
              doc.setFontSize(7);
              doc.text(55, 213, item.idPersona);

              doc.setFontSize(7);
              doc.text(148, 213, `${name} ${ap1} ${ap2}`);

              doc.setFontSize(8);
              doc.text(148, 200, "Nombre Afiliado");

              doc.setFontSize(7);
              doc.text(488, 213, remun.toString());

              doc.setFontSize(7);
              doc.text(532, 213, cotizacion.toString());

              doc.setFontSize(7);
              doc.text(582, 213, sis.toString());

              doc.setFontSize(7);
              doc.text(632, 213, "0");
            } else {
              let index = entityArray.indexOf(item);
              doc.setFontSize(7);
              doc.text(55, 213 + index * 9, item.idPersona);

              doc.setFontSize(7);
              doc.text(148, 213 + index * 9, `${name} ${ap1} ${ap2}`);

              doc.setFontSize(7);
              doc.text(488, 213 + index * 9, remun.toString());

              doc.setFontSize(7);
              doc.text(532, 213 + index * 9, cotizacion.toString());

              doc.setFontSize(7);
              doc.text(582, 213 + index * 9, sis.toString());

              doc.setFontSize(7);
              doc.text(632, 213 + index * 9, "0");
            }
          });
        }  
      });
      
           
    });
    for(let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
      
      console.log(paginacion)
      paginacion = paginacion + 1;
      doc.setPage(paginacion);

      
            if (totalRemHoja.length > 0) {
              doc.setFontSize(7);
              doc.text(488, 302, totalRemHoja[i - 1].toString());
            }

            if (totalCotHoja.length > 0) {
              doc.setFontSize(7);
              doc.text(532, 302, totalCotHoja[i - 1].toString());
            }

            if (totalSisHoja.length > 0) {
              doc.setFontSize(7);
              doc.text(582, 302, totalSisHoja[i - 1].toString());
            }
            console.log(i - 1, totalRemHoja, paginacion);
      
    doc.setFontSize(7);
    doc.text(168, 342, totalCotHoja[i - 1].toString());

    doc.setFontSize(7);
    doc.text(168, 352, totalSisHoja[i - 1].toString());

    doc.setFontSize(7);
    doc.text(168, 362, "0");

    doc.setFontSize(7);
    doc.text(168, 372, (totalCotHoja[i - 1] + totalSisHoja[i - 1]).toString());

    doc.setFontSize(7);
    doc.text(168, 382, (Math.round(totalRecargoHoja[i - 1])).toString());

    doc.setFontSize(7);
    doc.text(168, 392,  (Math.round(totalInteresHoja[i - 1] )).toString());

    doc.setFontSize(7);
    doc.text(168, 402, (Math.round(totalCotHoja[i - 1] + totalSisHoja[i - 1] + totalRecargoHoja[i - 1] + totalInteresHoja[i - 1])).toString());

    doc.setFontSize(7);
    doc.text(312, 342, (Math.round(store.detallePre.honorarios)).toString());
    
    }
    // Save the Data
    doc.save("Generated.pdf");
  };

  const generarPdf = async () => {
    const pdf = await actions.generarPdf(data)
    window.open(pdf)
  }

  return (
    <StyledButton onClick={(e) => generarPdf()} type="primary" primary pdf>
      {" "}
      Generar PDF
    </StyledButton>
  );
}
