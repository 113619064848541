import React, { useContext, useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Context } from "../AppContext";
import MainNavbar from "../components/MainNavbar";
import PdfSec from "../components/PdfSec";
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: "25ch",
    },
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 220,
  },
  root2: {
    margin: "auto",
    width: "10%",
    padding: "10px",
  },
}));

export default function Form() {
  const classes = useStyles();
  const { store, actions } = useContext(Context);

  console.log("afiliados",store.afiliadosCalculo)

  const [data, setData] = useState({
    razonSocial: '',
    idEmpleador: '',
    domicilio: '',
    comuna: '',
    ciudad: '',
    region: '',
    email: '',
    celNumber: '',
    nombreRepLegal: '',
    rutRepLegal: '',
    fondoPensiones: '',
    numeroFolio: '0',
    employer: '76835104K',
    paymentdate: '20220613',
    jperiods: ['0'],
    pperiods: ['0'],
    tipoPago: 'total'
  })

  useEffect(() => {

    console.log("store afiliados",store.afiliados)

    setData({
      ...data,
      ...store.afiliados,
      jperiods: store.afiliadosCalculo,
      pperiods: store.afiliadosCalculoPre,
      numeroFolio: store.folio,
      paymentdate: store.date
    })
  }, [store.folio,store.idEmpleador,store.date,store.afiliadosCalculo,store.afiliadosCalculoPre])

  const handleChange = e => {
		const { name, value } = e.target;
		setData({ ...data, [name]: value });
	};

  console.log("data",data)

  return (
    <>
      <MainNavbar />
      {store.loading && (
        <div className={classes.root2}>
          <CircularProgress />
        </div>
      )}
      {!store.loading && (
        <Container maxWidth="lg">
        <form className={classes.root} noValidate autoComplete="off">
          <div>
            <TextField
              id="outlined-helperText"
              label="Razón Social"
              name='razonSocial'
              defaultValue={data.razonSocial}
              onChange={(e) => handleChange(e)}
              variant="filled"
            />
            <TextField
              id="outlined-helperText"
              label="RUT Empleador"
              name='idEmpleador'
              defaultValue={data.idEmpleador}
              onChange={(e) => handleChange(e)}
              variant="filled"
            />
            <TextField
              id="outlined-helperText"
              label="Dirección"
              name="domicilio"
              defaultValue={data.domicilio}
              variant="filled"
              onChange={(e) => handleChange(e)}
            />
            <TextField
              id="outlined-helperText"
              label="Comuna"
              name="comuna"
              defaultValue={data.comuna}
              variant="filled"
              onChange={(e) => handleChange(e)}
            />
            <TextField
              id="outlined-helperText"
              label="Ciudad"
              name="ciudad"
              variant="filled"
              onChange={(e) => handleChange(e)}
            />
            <TextField
              id="outlined-helperText"
              label="Región"
              name="region"
              variant="filled"
              onChange={(e) => handleChange(e)}
            />
            <TextField
              id="outlined-helperText"
              label="Correo Electrónico"
              name="email"
              defaultValue={data.email}
              variant="filled"
              onChange={(e) => handleChange(e)}
            />
            <TextField
              id="outlined-helperText"
              label="Numero telefónico"
              name="celNumber"
              variant="filled"
              onChange={(e) => handleChange(e)}
            />
            <TextField
              id="outlined-helperText"
              label="Representante Legal"
              name="nombreRepLegal"
              defaultValue={data.nombreRepLegal}
              variant="filled"
              onChange={(e) => handleChange(e)}
            />
            <TextField
              id="outlined-helperText"
              label="RUT Representante Legal"
              name="rutRepLegal"
              defaultValue={data.rutRepLegal}
              variant="filled"
              onChange={(e) => handleChange(e)}
            />
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                Fondos de Pensiones
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // labelId="demo-simple-select-filled-label"
                // id="demo-simple-select-filled"
                label="valor"
                name="valor"
                value={data.valor}
                onChange={(e) => handleChange(e)}
              >
                <MenuItem value={"Efectivo"}>Efectivo</MenuItem>
                <MenuItem value={"Cheque"}>Cheque</MenuItem>
              </Select>
            </FormControl>
          </div>
        </form>
          <PdfSec data={data}/>
        </Container>
      )}
    </>
  );
}
